const api_path = window.location.hostname === 'localhost' ? 'http://localhost:3001' : 'https://rateyourlandlordpdx.com/api'//window.location.hostname === 'localhost' ? 'http://localhost:3001' : '/api'

export function submitContact(contact: any) {
  return fetch(`${api_path}/submit-contact`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(contact),
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
}

export function getStats() {
  return fetch(`${api_path}/stats`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((result) => result.json())
}