import { useRef } from "react";
import Join from "./Join";
import Collapsible from 'react-collapsible';

function FAQ() {


    return <div className="">
        <h1>FAQs</h1>
        <div className="faq-list">
            <li>
                <Collapsible trigger="What is a tenant union?">
                    <p>
                    A tenant union is a way for renters to collectively organize and advocate for the needs and desires of their community. They’re more informal than a labor union – there are no required votes to form, and there is no minimum or maximum membership. A tenant union can be as small as two neighbors, or be organized across an entire city. It's a really good way to get to know your neighbors and develop community!

                    </p>
                </Collapsible>
            </li>
            <li>
                <Collapsible trigger="What does a tenant union look like? How does it work?">
                    <p>
                    A tenant union is a way for renters to collectively organize and advocate for the needs and desires of their community. They’re more informal than a labor union – there are no required votes to form, and there is no minimum or maximum membership. A tenant union can be as small as two neighbors, or be organized across an entire city. It's a really good way to get to know your neighbors and develop community!
                    </p>
                    <p>Usually, it involves at least two things:
                    
                    <ol>
                        <li>
                        A way that tenants can communicate with each other, like a group chat for their apartment building. Ideally, as many of the tenants living in the building are invited to the group as possible. This communication should also be private so that the landlord cannot access it.
                        </li>
                        <li>
                        Some regular schedule of in-person or video chat meetings where the tenants get together to specifically lay out what issues they want to tackle and to make plans to address them.
                        </li>
                    </ol>
                    </p>
                </Collapsible>
            </li>
            <li>
                <Collapsible trigger="What can tenant unions help with?">
                    <p>
                    Neighbors help solve their collective challenges – from pet sitting or package pick-up to collectively bargaining with your landlord for cheaper rent and timely maintenance.
                    </p>
                    <p>
                    Here are some issues that tenant unions in Portland have recently tackled with their building owners:
                    <ul>
                        <li>
                            Getting them to complete long-ignored requests for maintenance and repairs
                        </li>
                        <li>
                        Negotiating rent credits for months that amenities – like secure mail lockers or a working elevator – were not available
                        </li>
                        <li>
                        Dangerous lack of weatherization – like salting icy steps in the winter
                        </li>
                        Lack of adequate heat during the winter
                        <li>
                        Inconsistent and unclear utility bills
                        </li>
                        <li>
                        Lack of compensation when tenants were forced to relocate from damaged or uninhabitable apartments
                        </li>
                        <li>
                        Incorrect rent ledgers or “lost” rent payments
                        </li>
                    </ul>
                    </p>
                </Collapsible>
            </li>
            <li>
                <Collapsible trigger="How do tenant unions bargain with their landlords?">
                <p>
                Generally, the tenant union comes together to write out all of the issues they want to collectively address. It’s important to be as clear as possible about the timeline of the issues, and the impact they’ve had on the tenants. If there is evidence of the problems – like photos, videos, or emails with management – those might be collected into a shared google drive folder.</p>
                <p>
Next, the union will also write out exactly what actions they would like to see management take – like establishing specific timelines for maintenance, or providing financial compensation to residents.
                </p>
                <p>
Lastly, the union will decide what actions they will take if their demands are not met. This is sometimes called an escalation strategy. It can include leaving negative reviews of the apartment building on Google Maps and other platforms, filing code complaints with local authorities, talking to the press about the issues at the building, or even running a rent strike.
                </p>
                <p>
The union will then deliver this information to management. This could take the form of a demand letter signed by all of the members of the tenant union, or it could be delivered in a meeting with management. Regardless, the union should communicate timeline expectations – e.g. we want action on our demands within 2 weeks, or we will follow through on our escalation strategy.
                </p>
                <p>
It’s unlikely that landlords will immediately agree to 100% of the demands exactly as requested. It will be up to the union to decide what, if anything, they are willing to compromise on. In many cases, given the dire circumstances of the issues many tenants are forced to live with, it is reasonable not to compromise at all.

                </p>
                </Collapsible>
            </li>
            <li>
                <Collapsible trigger="Why would landlords give in to tenant demands?">
                <p>
                The entirety of their business and income relies on the willingness of existing tenants to continue paying them rent, and for new tenants to move in to fill vacancies. When issues at a building are well publicized, it becomes harder for landlords to justify high rent prices and to convince new tenants to move-in. They literally cannot afford to let this happen.
                </p>
                <p>
                It is easy for landlords to dismiss, harass, or try to punish an individual resident who is alone in advocating for themselves. Landlords rely on the fact that many renters don’t know or talk with many of their neighbors, and they often don’t discuss things like disparities in rent prices and ignored maintenance.
                </p>
                <p>
                When many residents band together – particularly if they represent all or most of a building – it becomes very difficult for the landlord to continue ignoring demands. It is also illegal for landlords to retaliate against tenants for organizing and advocating with a tenant union. As with labor unions, there is safety in numbers.
                </p>
                <p>
                As an example, one person’s negative Google review of their apartment building will do little to affect the overall rating – but 20-30 reviews can easily drop a rating low enough to make it toxic to new potential tenants. The public will see the low rating, photos of disrepair, and angry reviews anytime they Google the building or search the address on Maps – something nearly all prospective residents will do at some point before applying.
                </p>
                <p>
                In short, tenant unions can easily mess with a landlord’s money – the only thing they care about, and the only thing that will motivate them to be better.
                </p>
                </Collapsible>
            </li>
            <li>
                <Collapsible trigger="How do I get started forming a tenant union in my building?">
                <p>
                    <ol>
                        <li>
                        Talk to Neighbors you already know
                            <p>
                                See if they are willing to help you organize the building and talk to other neighbors
                            </p>
                        </li>

                        <li>
                        Knock doors throughout your building
                            <p>
                            Identify issues that affect all or most people in the building
                            </p>
                            <p>
                            Ask your neighbors questions:
                            <ol>
                                <li>
                                “What has your experience been like living here?”
                                </li>
                                <li>
                                “Have you been treated fairly?”
                                </li>
                                <li>
                                “Does your maintenance get done?”
                                </li>
                                <li>
                                “What are you concerned about with your housing?”
                                </li>
                            </ol>
                            <p>
                            Ask for contact info - phone and/or email
                            </p>
                            <p>Track everyone you’ve knocked and talk to
                            </p>
                            <p>Repeat until you’ve connected with all or most of the people in the building
                            </p>
                            </p>
                        </li>
                    <li>Form a group chat</li>
                    <li>Setup a meeting time</li>
                    </ol>

                </p>
                </Collapsible>
            </li>
        </div>
        
        <Join></Join>

    </div>
}

export default FAQ;