import { useRef } from "react";
import Join from "./Join";


function About() {


    return <div className="about-us">
        <h1>About Us</h1>
        <p>
            Renters Action Network (RAN) is a grassroots organization dedicated to advocating for the rights and needs of renters across our community in Portland Oregon. We believe that safe, affordable, guaranteed housing is a fundamental human right, not a privilege.
            <br /><br />
            Founded on the principles of direct action and community empowerment, RAN is a coalition of <strong>renters</strong>, <strong>activists</strong>, and <strong>organizations</strong> that are committed to fighting systemic injustices in housing.
            <br /><br />
            <strong>We want to build a movement of tenants, and build power in the renter class, to address the unlivable price of rent, unjust evictions, and substandard living conditions.</strong>
            Through advocacy, education, and mobilization, RAN aims to create tangible change in housing policies and practices. We empower renters with knowledge of their rights, support the formation of tenant unions, and organize collective actions to hold landlords and politicians accountable.
            Together, we work towards building communities where renters are respected, protected, and empowered to thrive.

            <br /><br />
            {/* <button className="join-us-button">Join us</button> in our mission to create a more just and equitable housing landscape for all. */}
        </p>
        <Join></Join>

    </div>
}

export default About;