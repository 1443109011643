import { forwardRef, useEffect, useRef, useState } from 'react';
import './App.scss';
import { submitContact } from './services';
import Collapsible from 'react-collapsible';
import ScrollIntoView from 'react-scroll-into-view'
import useOnScreen from './UseOnScreen';
// import 'reactjs-popup/dist/index.css';

const skills = [
    {
        text: "tenant organizing",
        id: 'dto'
    },
    {
        text: "coding",
        id: 'tech',
        // html: (
        //     <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <rect width="24" height="24" rx="4" fill="#2D2D2D"/>
        //     <path d="M8 16L4 12L8 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     <path d="M16 8L20 12L16 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     <path d="M12 4L12 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     </svg>
        // )
    },
    {
        text: "housing law",
        id: 'law',
        // html: (
        //     <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M3 10L12 2L21 10V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V10Z" stroke="black" stroke-width="2" fill="none"/>
        //     <rect x="10" y="14" width="4" height="7" stroke="black" stroke-width="2" fill="none"/>
        //     </svg>

        // )
    },
    {
        text: "bringing people together",
        id: 'org'
    },
    {
        text: "making political art",
        id: 'art'
    },
    {
        text: "social media",
        id: 'social'
    },
    {
        text: "writing",
        id: 'write'
    },
    {
        text: "social work",
        id: 'sw'
    },
    {
        text: "I just want to help",
        id: 'help'
    }
]

const Join = ({
    title,
    header = 'Join Us!',
    showSkills = true,
    showDTO = false
}: {
    title?: string,
    header?: string,
    showSkills?: boolean,
    showDTO?: boolean
}) => {  
    const [selected, setSelected] = useState([] as boolean[])
    const [text, setText] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [joined, setJoined] = useState(false)
    const [valid, setValid] = useState(false)
    const [orgYourBuilding, setOrgYourBuilding] = useState(false)
    const [organizeBuildings, setOrganizeBuildings] = useState(false) 
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)

    function submitMe(name: string, email: string) {
        const s = selected.map((s, i) => s ? skills[i].id : null).filter(s => s)
        if (orgYourBuilding) {
            s.push('org-your-building')
        }
        if (organizeBuildings) {
            s.push('organize-buildings')
        }
        submitContact({
            name,
            email,
            info: text,
            skills: s
        }).then((response) => {
            console.log('response: ' + response)
            setJoined(true)
        })
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        if (name.length > 0 && validateEmail(email)) {
            setValid(true)
        } else {
            setValid(false)
        }
    }, [name, email])


    return (

        <div className="join">
            {
                !isVisible && !joined &&
            <ScrollIntoView selector="#join-box">
                <button className='join-anchor'>
                    {title ? title : 'JOIN US'}
                </button>
            </ScrollIntoView>
            }
            {!joined ?
                <div id='join-box' ref={ref}>
                    <h3>{header}</h3>
                    <h4 className='italic'>Together we can build renter power in this city</h4>
                    {showDTO &&
                        <div className='dto-questions'>
                            <input type="checkbox" id="dto-1" value="dto" onChange={(event) => {
                                setOrgYourBuilding(event.target.checked)
                            }}></input>
                            <label htmlFor="dto-1">Do you want to organize your building?</label>

                            <br/>
                            <br/>
                            <input type="checkbox" id="dto-2" value="dto" onChange={(event) => {
                                setOrganizeBuildings(event.target.checked)
                            }}></input>
                            <label htmlFor="dto-2">Do you want to help us organize buildings around the city?</label>
                        </div>
                    }
                    {showSkills &&
                    <><p className='good-at'>What are you good at? <span className='small'>select all that apply</span></p><div className='skills-container'>
                            {skills.map((skill, i) => <button className={'skill' + (selected[i] ? ' selected gelatine' : '')} onClick={() => {
                                const s = [...selected];
                                s[i] = !s[i];
                                setSelected(s);
                            } }>
                                {/* {
skill.html &&
<span className='skill-span'>{skill.html}</span>
} */}
                                {skill.text}
                            </button>
                            )}
                        </div></>
                }
                    <div className='textarea-container'>
                        <textarea
                            placeholder="(optional) Anything you want us to know about you?"
                            className='textarea'
                            name="Text1"
                            cols={40}
                            rows={5}
                            maxLength={600}
                            onChange={(event) => {
                                setText(event.target.value)
                            }}>
                        </textarea>
                        <br />
                        <span className='char-counter'>{text.length} / 600</span>
                    </div>
                    <div className="org-join">

                        <br />
                        <label className="small-text">(You don't have to use your real name)</label>
                        <input
                            type="text"
                            value={name}
                            placeholder="Your Name"
                            onChange={event => {
                                setName(
                                    event.target.value
                                );
                            }}
                        ></input>
                        <input type="email"
                            value={email}
                            placeholder="Email"
                            onChange={event => {
                                setEmail(
                                    event.target.value
                                );
                            }}></input>
                        <button
                            disabled={!valid}
                            className='gelatine'
                            onClick={() => {
                                submitMe(name, email)
                            }}>add me!</button>

                    </div>
                </div> :
                <>
                    <p>Thanks for Joining! We'll reach out soon</p>

                </>

            }


        </div>

    );
}

export default Join;