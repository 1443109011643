import Collapsible from "react-collapsible";
import Join from "./Join";

function TenantOrg() {
    return <div className="DTO">
        <h1>Direct Tenant Organizing</h1>
        <img
            className='center-img'
            alt='crabs'
            src={`images/crabs.png`}></img>
        {/* <Collapsible trigger="What is Direct Tenant Organizing?">
        <p>
            This is the collapsible content. It can be any element or React
            component you like.
        </p>
        <p>
            It can even be another Collapsible component. Check out the next
            section!
        </p>
        </Collapsible>
        <Collapsible trigger="Learn how to help">
        <p>
            This is the collapsible content. It can be any element or React
            component you like.
        </p>
        <p>
            It can even be another Collapsible component. Check out the next
            section!
        </p>
        </Collapsible> */}
        <p className="dto-p">            
            <h2 className="what-is">What is it?</h2>

            <h4>It's when renters in a building come together to organize and advocate for their rights and interests.</h4>
            <h2>How do we do it?</h2>
            <h4>We organize meetings with renters from a building to discuss common issues and to form a tenant union.</h4> 
            <h3>What's a tenant union?</h3>
            A tenant union is a collective organization formed by renters within a specific area or building to advocate for their rights and interests. The primary purpose of is to <strong>empower renters</strong>, address common concerns, and help negotiate with landlords or property management.
            Tenant unions work to ensure: 
            <ul>
                <li><strong>fair treatment</strong></li>
                <li><strong>affordable housing</strong></li>
                <li><strong>safe living conditions</strong></li>
            for all members, especially when dealing with negligent or exploitative landlords and property managers.
            </ul>

            <h2>We aim to:</h2>
            <ul>
                <li><strong>Provide</strong> information and resources to tenants about their rights under local housing laws and regulations.</li>
                <li><strong>Organize</strong> renters to vote for elected officials and ballot measures that will lower the price of rent and utilities, decrease the cost of living, and protect renters from harassment, negligence, and eviction.</li>
                <li><strong>Hold</strong> meetings and events to discuss common issues and concerns among tenants.</li>
                <li><strong>Help</strong> negotiate with landlords or property management to address grievances, such as rent increases, maintenance issues, or housing quality.</li>
                <li><strong>Advocate</strong> for policy changes at the local, state, and national level to improve tenant rights and lower the cost of living.</li>
                <li><strong>Build</strong> solidarity among tenants to strengthen their collective bargaining power and ability to effect change.</li>
            </ul>
        </p>

        <Join 
            title="Do you want to be a tenant organizer?"
            header="Join our team of Tenant Organizers!"
            showDTO={true} 
            showSkills={false}></Join>
    </div>
}

export default TenantOrg;